import type { ComponentType } from "react"
import { useState, useEffect, useRef } from "react"

export function withProgress(Component): ComponentType {
    return (props) => {
        const [progress, setProgress] = useState(0)
        const [isScrolling, setIsScrolling] = useState(false)
        const prevScrollY = useRef(0)

        useEffect(() => {
            const calculateProgress = () => {
                const startEl = document.getElementById("start")
                const endEl = document.getElementById("end")
                if (startEl && endEl) {
                    const sectionHeight = endEl.offsetTop - startEl.offsetTop
                    const scrollDistance =
                        window.pageYOffset || document.documentElement.scrollTop
                    const sectionScrollDistance =
                        scrollDistance - startEl.offsetTop
                    const sectionProgress =
                        (sectionScrollDistance / sectionHeight) * 100
                    setProgress(sectionProgress)
                }
            }

            const handleScroll = () => {
                const scrollY =
                    window.pageYOffset || document.documentElement.scrollTop
                if (scrollY !== prevScrollY.current) {
                    prevScrollY.current = scrollY
                    calculateProgress()
                    setIsScrolling(true)
                }
            }

            const handleScrollEnd = () => {
                setIsScrolling(false)
            }

            calculateProgress()

            window.addEventListener("scroll", handleScroll)
            window.addEventListener("scrollend", handleScrollEnd)

            return () => {
                window.removeEventListener("scroll", handleScroll)
                window.removeEventListener("scrollend", handleScrollEnd)
            }
        }, [])

        return (
            <Component
                {...props}
                style={{
                    width: `${progress}%`,
                    transition: isScrolling ? "none" : "width 0.5s ease-in-out",
                }}
            />
        )
    }
}
